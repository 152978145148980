import { supportForm, websiteForm } from "./asyncThunk";

export const websiteFormBuilder = (builder) => {
  builder.addCase(websiteForm.pending, (state, { meta }) => {
    state.websiteForm.loading = true;
  });

  builder.addCase(websiteForm.fulfilled, (state, { payload }) => {
    const { fullName, email, type, data } = payload;
    state.websiteForm.loading = false;
    state.websiteForm.fullName = fullName;
    state.websiteForm.email = email;
    state.websiteForm.type = type;
    state.websiteForm.data = data;
  });

  builder.addCase(websiteForm.rejected, (state) => {
    state.websiteForm.loading = false;
  });
};

export const supportFormBuilder = (builder) => {
  builder.addCase(supportForm.pending, (state, { meta }) => {
    state.supportForm.loading = true;
  });

  builder.addCase(supportForm.fulfilled, (state, { payload }) => {
    const { fullName, email, phoneNumber, description } = payload;
    state.supportForm.loading = false;
    state.supportForm.fullName = fullName;
    state.supportForm.email = email;
    state.supportForm.phoneNumber = phoneNumber;
    state.supportForm.description = description;
  });

  builder.addCase(supportForm.rejected, (state) => {
    state.supportForm.loading = false;
  });
};
