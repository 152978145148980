import axios from "axios";
import { handleError, throwErrorIfWrongStatus } from "./utils";

class FormService {
  websiteForm = async (requestData) => {
    try {
      const response = await axios.post(
        "https://eycrowd-server.production.eycrowd.com/api/feedback/website-form",
        requestData
      );
      throwErrorIfWrongStatus(response, 201);
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while posting form request.");
    }
  };
  supportForm = async (requestData) => {
    try {
      const response = await axios.post(
        "https://hooks.zapier.com/hooks/catch/11013572/3hq9fw1/",
        requestData
      );
      throwErrorIfWrongStatus(response, 201);
      return response.data;
    } catch (err) {
      throw handleError(err, "Error occurred while posting support request.");
    }
  };
}

const formService = new FormService();

export default formService;
